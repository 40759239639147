<template>
  <div class="more-text">
    <div class="title" ref="titleRefs">{{ title }}：</div>
    <el-tooltip
        :content="text"
        raw-content
        placement="top"
        effect="light"
    >
      <span class="text" v-html="text"></span>
    </el-tooltip>
  </div>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref, toRefs} from "vue";
import {useEventListener} from "@/utils/tool/event"

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  }
})
const {text, title} = toRefs(props)
const {vnode} = getCurrentInstance()
const width = ref(0)
const titleWidth = ref(0)
const titleRefs = ref(null)
const setWidth = () => {
  width.value = vnode.el.parentElement.offsetWidth
}
useEventListener(window, 'resize', () => {
  setWidth()
})


onMounted(() => {
  setWidth()
  titleWidth.value = titleRefs.value.offsetWidth
})
</script>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'MoreText',
})
</script>

<style lang="scss" scoped>
.more-text {
  display: flex;
  align-items: center;

  .title {
    font-size: 14px;
    color: $fsColor2;
  }

  .text {
    color: $fsColor;
    @include textOverflow(calc(v-bind(width) * 1px - v-bind(titleWidth) * 1px - 60px))
  }
}
</style>